// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-components-js": () => import("./../../../src/pages/components.js" /* webpackChunkName: "component---src-pages-components-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-style-buttons-js": () => import("./../../../src/pages/style/buttons.js" /* webpackChunkName: "component---src-pages-style-buttons-js" */),
  "component---src-pages-style-icons-js": () => import("./../../../src/pages/style/icons.js" /* webpackChunkName: "component---src-pages-style-icons-js" */),
  "component---src-pages-style-index-js": () => import("./../../../src/pages/style/index.js" /* webpackChunkName: "component---src-pages-style-index-js" */),
  "component---src-pages-style-typography-js": () => import("./../../../src/pages/style/typography.js" /* webpackChunkName: "component---src-pages-style-typography-js" */),
  "component---src-pages-templates-js": () => import("./../../../src/pages/templates.js" /* webpackChunkName: "component---src-pages-templates-js" */),
  "component---src-pages-tutorials-js": () => import("./../../../src/pages/tutorials.js" /* webpackChunkName: "component---src-pages-tutorials-js" */),
  "component---src-pages-updates-js": () => import("./../../../src/pages/updates.js" /* webpackChunkName: "component---src-pages-updates-js" */),
  "component---src-templates-component-js": () => import("./../../../src/templates/component.js" /* webpackChunkName: "component---src-templates-component-js" */),
  "component---src-templates-template-v-2-js": () => import("./../../../src/templates/template-v2.js" /* webpackChunkName: "component---src-templates-template-v-2-js" */),
  "component---src-templates-tutorial-js": () => import("./../../../src/templates/tutorial.js" /* webpackChunkName: "component---src-templates-tutorial-js" */),
  "component---src-templates-type-js": () => import("./../../../src/templates/type.js" /* webpackChunkName: "component---src-templates-type-js" */),
  "component---src-templates-update-js": () => import("./../../../src/templates/update.js" /* webpackChunkName: "component---src-templates-update-js" */)
}

